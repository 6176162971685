
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium_Web/TitilliumWeb-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium_Web/TitilliumWeb-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 80vh;
}


a {
  font-family: 'Titillium Web', sans-serif;;
  font-weight: 700;
}

.grid-x {
  display: flex;

}

header {
  background: #D8D8D8;
  box-shadow: 0px 2px 7px #444;
  position: absolute;
  height: 50px;
  width: 100%;
  z-index: 2;
}

header >  a > img {
  height: 35px;
  padding: 6px 88px 3px;
}

.footer {
  background: #666666;
  border-top: 12px solid #dcb47d;
  padding: 3px;

}

.footer > div {
  padding: 20px;
  flex-grow: 1;

}
.footer .footer-logo {
  flex-grow: 2;
}
.footer .footer-logo img {
  width: 10rem;
  padding: 22px 88px;
  
}

.footer h2 {
  color: #C1C1C1;
  font-family: 'Titillium Web', sans-serif;;
  font-weight: 600;
}


.footer {
  
  font-family: 'Titillium Web', sans-serif;;
  font-weight: 300;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  
}



.footer a{
 color: #fff;
 text-decoration: none;
}

footer .footer_menu {

}

.configurator_block {
  height: 80vh;  
  overflow: hidden;
  width: 100%;
  background: #d8d8d8;
}

.configurator_block .scene {
  position: absolute;
  left: -30%;
  width: 130%;
  height: 80vh;
}

#configrator {
  position: absolute;

  top: 20%;
  left: 65%;
  right: 1%;
}

.swipe_icon {
  opacity: .2;
  position: absolute;
  top: 70vh;
  left: 33%;
  color: #666;
  transform: scale(1);
  border-radius: 3rem;
  padding: 1rem;
  animation: swipeIcon  8s infinite;
  animation-delay: 3s;
}    

@media screen and (max-width: 640px) {
  html {
    font-size: 14px;
  }
  
  header >  a > img {
    padding: 6px 22px 3px;
  }

  .footer{
    flex-direction: column;
    align-items: center;
  }
  .footer > div {
    width: 300px;
  }

  .footer .footer-logo img {
    width: 10rem;
    padding: 22px;
    
  }
  
}


@media screen and (max-height: 620px) {
  html {
    font-size: 14px;
  }

  .configurator_block {
    height: calc(100vh + 125px);  
  }

  .configurator_block .scene {
    height: 100vh;
  }
}
@media screen and (max-width: 460px) {
  .configurator_block #configurator {
    position: relative;
    top: 50vh;
    width: 80%;
    left:auto;
    margin: 0px auto;
  }

  header >  a > img {
    padding: 6px 22px 3px;
  }
  .configurator_block {
      height: calc(70vh + 405px);  

  }

  .configurator_block .scene {
    left:0%;
    width: 100%;        
    height: 70vh;

  }
  

  


  .footer {
  
    display: flex;
    flex-direction: column;
    
  }
  
}


@keyframes swipeIcon {
  0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	10% {
		transform: scale(1.1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    opacity: .8;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}


@keyframes swipeIcon2 {
  0% {
		transform: scale(0.95);
    opacity: 1;
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	10% {
		transform: scale(1.1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    opacity: .8;
	}

	100% {
		transform: scale(0.95);
    opacity: 0;
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

svg.loading_icon_path {
  stroke-dasharray: 100;
  stroke-dashoffset: 300;
  animation: dash 5s linear infinite;
}

svg.loading_icon_path_pulse {
  animation: swipeIcon2  2s forwards;
  border-radius: 1rem;
  padding: 2rem;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}