
@mixin navitem {
    border:1px solid #b1b4a9;
    border-radius: 2rem;
    padding: 0px 1rem;
    color: #2c2c2c;
    text-decoration: none;
    font-size: 1.3rem;
    color: #b1b4a9;

    transition: color .2s;
}

$color-gold: #dcb47d;
$color-white: #fafafa;
#configrator {

    background: $color-white;    
    padding: 20px;
    box-shadow: 1px 1px 10px #666;
    border-radius: 4px;
    max-width: 310px;
    min-width: 175px;
    overflow: hidden;
    display: flex;
    color: #333;


    @media screen and (max-width: 460px) {
        
          position: relative;
          top: 50vh;
          width: 80%;
          left:auto;
          margin: 0px auto;
    }

    > div {
        flex-direction: column;
        display: flex;
        width: 100%;
        transition: all .2s;
        flex-shrink: 0;

        
    }

    .options {

        &.hidden {
            transform: translateX(-120%);
        }
        
    }

    .request_form {
        transform: translateX(-100%);

        &.hidden {
            transform: translateX(10%);
        }
        
    }


    .switcher {
        border:3px solid #f1f1f1;
        border-radius: 2rem;
        position: relative;

        display:flex;
        justify-content: space-between;
        box-shadow: 0px 0px 3px rgb(138, 204, 206);
        transition: all .2s;
        &:hover {
            box-shadow: 0px 0px 5px cadetblue;
        }

        input {
            position:absolute;
            opacity: 0;
        }
        label {
            display: inline-flex;
            cursor: pointer;
            padding: 1rem 1.2rem 1rem 1.5rem;
            position: relative;
            z-index:2;
            color: #888;

        }

        input:not(:checked) + label:hover {
            color: #444;
        }

        input:checked + label {
            color:white;
        }

        input:disabled + label {
            color: #ccc;
        }

        .switch {
            position: absolute;
            display: flex;
            top: 1px;
            left: 1px;
           width: 100%;
           &::before {
               content: '';
               display: block;
               transition: all .2s;
           }
           &::after {
            content: '';
            display: block;            
            height: 3rem;
            width: 6rem;
            margin-right: 2px;
            background-color: #2d3e50;
            border-radius: 50em;
            
           }

           
        }

        input:checked + label + .switch {
            &::before{
                flex-grow: 1;
            }
            
        }
        
    }
    
    .models_nav {
        padding-top:.5rem;
        strong {
            
            line-height: 1.5rem;
            padding-left: .5rem;
            color:#222;
            display: block;
            font-size: .8rem;
        }
         ul {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid #cacdc1;
            padding-bottom: 10px;
            
            
            li {
                padding: 10px;

                a {
                    cursor: pointer;
                    position: relative;

                    display: flex;
                    align-items: center;
                    justify-content: flex-end;  
                    text-transform: uppercase;
                
                    @include navitem;
                    


                    svg {
                        padding-right: .5rem;
                                            
                    }

                    &:not(.active){
                        &:hover {
                            color: #6e7261;
                            border-color: #6e7261;
                        }

                        

                    }

                    &.active {
                        background: #2d3e50;
                        color: white;
                        border:1px solid #323a1b;

                    
                        
                    }
                    

                }
            }
         }
    }


    .theme_nav {
        padding: 1.5rem 10px;

        a {
            @include navitem;
            padding: 3px 1rem;
            font-size: 1.2rem;
            border-color: #dccb83;
            color:#c2ae94;
            cursor: pointer;

            &:first-child {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;

                &:not(.active) {
                    border-right: none;
                    
                }
            }
            &:last-child {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                &:not(.active) {
                    border-left: none;                    
                }
            }

            &.active {
                background: $color-gold;
                color: #fff;
                border:1px solid #9e9c5e;



            }
            &:not(.active){
                &:hover {
                    border-color: #caba72;
                    color:#978267;
                }

                

            }

        }
        
    }

    .grow {
        flex-grow: 1;

        @media screen and (max-width: 760px) {
            
            flex-grow: 0;
        }
    }

    .outer_panel_nav {
        display: flex;
        padding: 0 .5rem;
        

        &.outer_panel_light > .dark {display: none;}
        &.outer_panel_dark > .light {display: none;}

        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                padding: .5rem;
            }
        }


        a {
            position: relative;
            display: flex;
            transition: .2s ease-out;
            cursor: pointer;
            .pic {
                width: 3.5rem;
                height: 3.5rem;
                mask-image: url('./statics/pic_mask.svg');
                z-index: 1;
                + svg {
                    position: absolute;
                    top:0px;
                    left:0px;
                    width:3.5rem;
                    height: 3.5rem;
                    stroke: #b1ad98;
                    stroke-width: 4px;
                }
    
            }
    
            &:not(.active) {
                &:hover {
                transform: scale(1.03);

                svg {
                    stroke: #ddcfa9;
                    stroke-width: 2px;
                    
                }
            }
        }

            svg.active {

                position: absolute;
                top:-4px;
                left:-4px;
                width:calc(3.5rem + 8px);
                height: calc(3.5rem + 8px);
                fill: #f5e0c4;
                z-index: 0;
                

            }
        }
        

      
    }


    .notification_text {
        color: #333;
        font-size: .8rem;
        padding: 1rem .5rem .0rem;
    }
}

.pic_dark {
    background: #333;
}
.pic_white {
    background: #fff;
}

.pic_wood {
    background: url('./texture_pics/wood.png') no-repeat;
}
.outer_panel_outdoor {
    .pic_wood {
        transform: rotate(90deg);
        background: url('./texture_pics/grey_wood.png') no-repeat;
    }   
}

.pic_image {
    
    background: url('./texture_pics/rainbow.png') #f1f1f1 no-repeat;
    background-size:4.5rem;
}


.RequestButton {

    @include navitem;
    padding: .8rem 1rem;
    font-size: .9rem;
    font-weight: 600;
    border-color: $color-gold;
    color:#c2ae94;

    width: fit-content;
    margin: 1.5rem auto 0.5rem;
    cursor: pointer;
    transition: .2s ease-out;
    background: $color-white;
    
    &:hover {
        background: #fff;
        box-shadow: 0px 0px 4px #dccb83;
    }

    > svg {
        margin-left: 1rem;
    }
}


.RequestSendButton {

    @include navitem;
    display: flex;
    align-items: center;
    padding: .8rem 1rem;
    font-size: .9rem;
    font-weight: 600;
    border-color: $color-gold;
    color:#c2ae94;

    width: fit-content;
    margin: 1rem auto 0.5rem;
    cursor: pointer;
    transition: .2s ease-out;
    background: $color-white;
    
    &:hover {
        background: #fff;
        box-shadow: 0px 0px 4px #dccb83;
    }


    svg {
        width: 2rem;
        height: 2rem;
        margin-left: .5rem;
    }
}


.RequestBackButton {

    padding:  0rem;
    font-size: .9rem;
    font-weight: 700;
    border: none;
    background: none;
    width: fit-content;
    cursor: pointer;
    transition: .2s ease-out;
    color:#e6870a;


    &:hover {
        text-decoration: underline;
    }

    > svg {
        transform: rotate(180deg);
        margin-right: 1rem;
    }
}


.request_form { 

    a {
        color: $color-gold;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        &:visited {
            color: #e6870a;
        }

    }

    h4 {
        margin: 0;
        font-size: .9rem;
        margin-bottom: .5rem;
        &:first-child{
            margin-top: 1.3rem;
        }
    }

    > div {
        margin-bottom: .5rem;

        &.success {
            width: 30%;
            margin: auto;
        }
    }

    .chosen_options {

        h5 {
            margin: 0;
            margin-top: .5rem;
            padding: 0px;
            padding-left: .7rem;
            font-size: .8rem;
            color: #666;
        }

        > ul {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;

            background: #f9f2e8;
            padding-bottom: 1rem;
            border-radius: 4px;

            .product_model, .theme_outer_panel {
                position: relative;
                display: flex;
                margin: .4rem .1rem .2rem;
                box-shadow: 0px 0px 8px #ddd;

                border-radius: 2rem;
                overflow: hidden;
                > span {
                    display: block;

                
                    padding: 0px 1rem;
                    text-decoration: none;
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 2;
                    color: #94988a;

                    &:first-child {

                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;

                    }

                    &:last-child {

                        border-left: none;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        min-width: 1.2rem;
                    }
                }
            }

            .product_model {
                > span:last-child {
                    background: #2d3e50;
                    color: white;
                    text-transform: uppercase;
                    font-weight: 600;
                    text-align: center;
                }
            }

            .theme_outer_panel {
                .pic_image {
                    background-size: 3.5rem;
                }
            }
        }


    }


    .input_comment {

        input, textarea {
            resize: none;
            border: none;
            width: calc(100% - 2rem);
            font-family: 'Helvetica';
            padding: 1rem;
            font-size: .9rem;
            border-bottom: white solid 2px;
            background: #bbb;
            

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #fff;
                opacity: 1; /* Firefox */
                font-style: italic;
            }
            &:focus {
                outline: none;
                border-bottom: $color-gold solid 2px;
                background:white;
                font-style: normal;
                color: #222;

            }
        }

        .nonvalid {
            border-bottom: red solid 2px;
        }
    }
  
}

        

.checkbox_switch {

    display: flex;
    position: relative;
    padding: .5rem 0;
    

    input[type=checkbox] {
        position: absolute;
        height: 0;
        width: 0;
        visibility: hidden;
    }

    &.nonvalid {
        label::before {
            background: red;
        }
    }

    label {
        height: calc(1.1rem + 4px);

        display:block;
        cursor: pointer;
        text-indent: 2.8rem;
        position: relative;

        font-size: .9rem;
        line-height: 1.4;
        
        
        &:before {
            left: 0;
            top:0;
            content: '';
            display: block;
            width: 2.2rem;
            height: calc(1.1rem + 4px);
            position: absolute;
            border-radius: 1rem;
            background: grey;
          
            
        }
        

        &:after {
            content: '';
            display: block;
            position: absolute;
            border-radius: 50%;
            transition:.1s;
            width: 1.1rem;
            height: 1.1rem;
            top:2px;
            left:2px;
            background: #fff;
        }
    }


    input:checked + label {
       
        &:before {
            background: $color-gold;
        }

        &:after {
            transform: translateX(calc(2.2rem - 1.1rem - 4px));
        }
    }

}


svg.ok-icon {

    .circle{fill:none;stroke:#1E4164;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;}
    .circle-dash{fill:none;stroke:#1E4164;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;}
    .check{fill:none;stroke:#1E4164;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
    .check-dash{fill:none;stroke:#1E4164;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}

    .check {
        stroke-dasharray: 60 100;
        animation: check 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards 0.15s; 
        -webkit-animation: check 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards 0.15s; 
        -moz-animation: check 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards 0.15s; 
        -o-animation: check 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards 0.15s; 
        opacity: 0;
    }

    @-webkit-keyframes check {
        from {stroke-dashoffset: 60;
        opacity: 1;}

        to {stroke-dashoffset: 00;
        opacity: 1;}
    }

    @-moz-keyframes check {
        from {stroke-dashoffset: 60;
        opacity: 1;}

        to {stroke-dashoffset: 00;
        opacity: 1;}
    }

    @keyframes check {
        from {stroke-dashoffset: 60;
        opacity: 1;}

        to {stroke-dashoffset: 00;
        opacity: 1;}
    }

    .check-dash {
        stroke-dasharray: 10 100;
        animation: check-dash 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards; 
        -webkit-animation: check-dash 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards; 
        -moz-animation: check-dash 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards; 
        -o-animation: check-dash 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards; 
    }

    @-webkit-keyframes check-dash {
        from {stroke-dashoffset: 120;}
        to {stroke-dashoffset: 45;}
    }

    @-moz-keyframes check-dash {
        from {stroke-dashoffset: 120;}
        to {stroke-dashoffset: 45;}
    }

    @keyframes check-dash {
        from {stroke-dashoffset: 120;}
        to {stroke-dashoffset: 45;}
    }

    .circle {
        stroke-dasharray: 300 300;
        animation: circle 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.15s; 
        -webkit-animation: circle 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.15s; 
        -moz-animation: circle 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.15s; 
        -o-animation: circle 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.15s; 
        opacity: 0;
    }

    @-webkit-keyframes circle {
        from {stroke-dashoffset:300;
        opacity: 1;}
        to {stroke-dashoffset:0;
        opacity: 1;}
    }

    @-moz-keyframes circle {
        from {stroke-dashoffset:300;
        opacity: 1;}
        to {stroke-dashoffset:0;
        opacity: 1;}
    }

    @keyframes circle {
        from {stroke-dashoffset:300;
        opacity: 1;}
        to {stroke-dashoffset:0;
        opacity: 1;}
    }

    .circle-dash {
        stroke-dasharray: 10 300;
        animation: circledash 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.05s;
        -webkit-animation: circledash 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.05s;
        -moz-animation: circledash 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.05s;
        -o-animation: circledash 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.05s;
        opacity: 0;
    }

    @-webkit-keyframes circledash {
        from {stroke-dashoffset:320;
        opacity: 1;}
        to {stroke-dashoffset: 20;
        opacity: 1;}
    }

    @-moz-keyframes circledash {
        from {stroke-dashoffset:320;
        opacity: 1;}
        to {stroke-dashoffset: 20;
        opacity: 1;}
    }

    @keyframes circledash {
        from {stroke-dashoffset:320;
        opacity: 1;}
        to {stroke-dashoffset: 20;
        opacity: 1;}
    }

}